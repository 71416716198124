import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import pdf from '../../assets/documents/Program_Information_Confirmation_Document_2020_hair.pdf'

const pdfStyle = {
  width: '100%',
  height: '80vh',
}

const Pdf = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - BPPE Disclosures</title>
      <meta name="description" content="Generic Page" />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Cosmetology BPPE Disclosures</h1>
          </header>
          <iframe
            src={pdf + '#view=fit'}
            width="100%"
            height="100%"
            style={pdfStyle}
            title="BPPE Disclosures"
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Pdf
